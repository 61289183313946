var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hig-drop-down",
      staticStyle: { "min-width": "100%", "max-width": "100%" },
      attrs: { "data-value-target": _vm.dropdownData.name },
    },
    [
      _c("input", {
        attrs: {
          type: "hidden",
          name: _vm.dropdownData.name,
          value: "",
          "data-wat-val": _vm.dropdownData.dataWatVal,
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "selected", on: { click: _vm.toggleDropdown } },
        [
          _c("span", {}, [
            _vm._v(
              _vm._s(
                _vm.selectedOption.name !== undefined
                  ? _vm.selectedOption.name
                  : _vm.dropdownData.placeholder
              )
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _c(
              "svg",
              {
                staticClass: "arrow-up d-none",
                attrs: {
                  width: "12",
                  height: "16",
                  viewBox: "0 0 12 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M10.999 10.5L5.99902 5.5L0.999023 10.5",
                    stroke: "black",
                    "stroke-width": "1.5",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "arrow-down",
                attrs: {
                  width: "12",
                  height: "16",
                  viewBox: "0 0 12 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M10.999 5.5L5.99902 10.5L0.999023 5.5",
                    stroke: "black",
                    "stroke-width": "1.5",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "hig-drop-down-options", class: { active: _vm.isOpen } },
        [
          _c(
            "button",
            {
              staticClass: "connector-plugin-type-filter h5",
              class: { active: _vm.selectedOption.slug === undefined },
              attrs: { "data-target": "all" },
              on: {
                click: function ($event) {
                  return _vm.selectOption($event, { slug: undefined })
                },
              },
            },
            [
              _c("span", { staticClass: "option-text" }, [
                _vm._v(_vm._s(_vm.dropdownData.placeholder)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "selected-icon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "11",
                      height: "16",
                      viewBox: "0 0 11 16",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M0.999512 7.40887L4.18133 11.0015L9.99951 4.50146",
                        stroke: "black",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.dropdownData.items, function (item) {
            return _c(
              "button",
              {
                staticClass: "connector-plugin-type-filter h5",
                class: { active: _vm.selectedOption.slug === item.slug },
                attrs: { "data-target": item.slug },
                on: {
                  click: function ($event) {
                    return _vm.selectOption($event, item)
                  },
                },
              },
              [
                _c("span", { staticClass: "option-text" }, [
                  _vm._v("\n        " + _vm._s(item.name) + "\n      "),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "selected-icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "11",
                        height: "16",
                        viewBox: "0 0 11 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0.999512 7.40887L4.18133 11.0015L9.99951 4.50146",
                          stroke: "black",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }