<template>
  <p class="eyebrow short-copy mb-8 text-uppercase js-event-card-eyebrow">
    <template v-if="event">
      <template v-if="event.start_date">
        {{ event.start_date.month }}
        {{ event.start_date.day + (event.end_date ? ' -' : '') }}
        <template v-if="event.end_date && event.start_date.month != event.end_date.month">
          {{ event.end_date.month }}
        </template>
      </template>
      <template v-if="event.end_date">
        <template v-if="!event.start_date">
        {{ event.end_date.month }}
        {{ event.end_date.day }}
        </template>
        <template v-if="event.start_date">
          {{ event.end_date.day }}
        </template>
      </template>
      <template v-if="event.location && event.location.location">
        <template v-if="event.start_date || (event.end_date && !event.start_date)">|</template> {{ event.location.location }}
      </template>
    </template>
  </p>
</template>
<script>
export default {
  props: ['card'],
  data() {
    return {
      event: this.card.event
    }
  }
}
</script>
