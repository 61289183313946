var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass:
        "eyebrow short-copy mb-8 text-uppercase js-event-card-eyebrow",
    },
    [
      _vm.event
        ? [
            _vm.event.start_date
              ? [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.event.start_date.month) +
                      "\n      " +
                      _vm._s(
                        _vm.event.start_date.day +
                          (_vm.event.end_date ? " -" : "")
                      ) +
                      "\n      "
                  ),
                  _vm.event.end_date &&
                  _vm.event.start_date.month != _vm.event.end_date.month
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.event.end_date.month) +
                            "\n      "
                        ),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.event.end_date
              ? [
                  !_vm.event.start_date
                    ? [
                        _vm._v(
                          "\n      " +
                            _vm._s(_vm.event.end_date.month) +
                            "\n      " +
                            _vm._s(_vm.event.end_date.day) +
                            "\n      "
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.event.start_date
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.event.end_date.day) +
                            "\n      "
                        ),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.event.location && _vm.event.location.location
              ? [
                  _vm.event.start_date ||
                  (_vm.event.end_date && !_vm.event.start_date)
                    ? [_vm._v("|")]
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.event.location.location) + "\n    "),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }