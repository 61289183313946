<template>
  <div class="speakers js-event-card-speakers">
    <div
        class="speaker-buttons js-event-card-speaker-buttons">
      <template v-if="hasSpeakers">
        <button
            v-for="speaker in speakers"
            :key="speaker.id"
            :data-speaker-details="speaker.id"
            @click="setActiveSpeakerId($event, speaker.id)"
            :class="{active: (activeSpeakerId === speaker.id)}"
            class="">
          <img :src="speaker.image.url" style="width: 33px;height:33px; border-radius: 50%;"/>
        </button>
      </template>
    </div>
    <div class="speakers-details"  v-if="hasSpeakers">
      <div
          v-if="speakers.filter(e => e.id === activeSpeakerId)[0]"
          class="speaker-detail"
          :class="{active: activeSpeakerId, show: showActiveSpeaker}"
      >
        <p class="speaker-name">
          {{ speakers.filter(e => e.id === activeSpeakerId)[0].name }}
        </p>
        <p class="speaker-title">
          {{ speakers.filter(e => e.id === activeSpeakerId)[0].title }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['speakers', 'cardId', 'lastOpened', 'hasSpeakers'],
  data() {
    return {
      activeSpeakerId: -1,
      showActiveSpeaker: false,
    }
  },
  watch : {
    lastOpened: function (e) {
      if (this.cardId !== e) {
        this.closeDropdown()
      }
    }
  },
  methods: {
    closeDropdown: function () {
      this.showActiveSpeaker = 0
      setTimeout(() => this.activeSpeakerId = 0, 105 )
    },
    setActiveSpeakerId : function (e, id) {
      e.preventDefault()
      this.$emit('activeSpeakerChanged', {index: this.cardId})
      if (this.activeSpeakerId === id) {
        this.showActiveSpeaker = 0
        setTimeout(() => this.activeSpeakerId = 0, 105 )
      } else {
        setTimeout(() => this.activeSpeakerId = 0, 105 )
        // this.activeSpeakerId = 0
        this.showActiveSpeaker = 0
        setTimeout(() => {
          this.$nextTick(() =>  {
            this.activeSpeakerId = id
            setTimeout(() => this.showActiveSpeaker = true, 105 )
          });
        }, 105)
      }
    }
  }
}
</script>
