<template>
  <div class="container p-0">
    <div class="row">
      <div class="col-12 rebrand-content mb-48 ">
        <div class="row">
          <template v-for="(filter, i) in localFilters">
            <div class="col-lg-3 col-md-6">
              <label for="content_type_select">{{filter.title}}</label>
              <hig-dropdown @onSelected="getSelected"
                            @onOpened="onOpened"
                            :index="i"
                            :lastOpened="lastOpened"
                            :key="'filter_' + i"
                            :dropdown="filter"/>
            </div>
          </template>
          <div
              v-if="localFilters && localFilters.length"
              class="col-lg-3 col-md-6 form__buttons d-flex align-items-end">
            <div class="form-buttons d-flex align-items-center">
              <div>
                <a
                    @click="resetFilters"
                    href="#"
                    class="ml-2 d-inline-block cta-secondary btn" >Reset Filters</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row card-list ">
      <template v-for="i in cardsShownCount">
        <div class="col-sm-6 col-lg-3" v-if="filteredCards[i - 1] !== undefined">

          <event-card
              :card="filteredCards[i - 1]"
              @activeSpeakerChanged="onLastSpeakerOpened"
              :lastOpened="lastSpeakerOpened"
              :listingType="listingType"
              :key="'event_card_' + i"></event-card>
        </div>
      </template>
      <p v-if="filteredCards.length === 0">{{ noResultsMessage }}</p>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button class="button-see-more"
                v-if="cardsShownCount < filteredCards.length"
                @click="() => {cardsShownCount += localResourcefields.posts_per_page; equalHeight()}"
        >
                        <span class="button-icon">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1196 10.4832L9.49997 14.1028M9.49997 14.1028L5.8803 10.4832M9.49997 14.1028L9.49997 5.93738" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
          <span class="button-text">
                            See more
                        </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import HigDropdown from './hig-dropdown.vue'
import EventCard from './event-card.vue'
import $ from 'jquery'

export default {
  components: {
    EventCard,
    HigDropdown
  },
  props: ['data', 'foo'],
  data () {
    return {
      filters: [],
      cards: [],
      selected: {},
      lastOpened: -1,
      lastSpeakerOpened: -1,
      cardsShownCount: 0,
      filteredCards: [],
      localResourcefields: {},
      noResultsMessage: '',
      localFilters: {}
    }
  },
  beforeMount () {
    this.localResourcefields = this.resourceListFields
    this.noResultsMessage = this.resourceListFields.no_results_message
    this.localFilters = this.resourceListFields.filters
    this.listingType = this.resourceListFields.listingType
    this.getSelected()
    this.updateCardsShownCount()
  },
  mounted() {
    this.equalHeight()
  },
  methods: {
    resetFilters: function (e) {
      e.preventDefault()
      this.selected = {}
      this.lastOpened = -1
      this.cardsShownCount = this.localResourcefields.posts_on_first_load
      this.getSelected()
      this.equalHeight()
    },
    updateCardsShownCount: function () {
      this.cardsShownCount = Math.min(this.filteredCards.length, this.localResourcefields.posts_on_first_load)
    },
    equalHeight: function () {
        this.$nextTick(function () {
          $('.js-event-card-eyebrow').matchHeight({row: true})
          $('.js-event-card-text').matchHeight({row: true, property: 'min-height'})
          $('.js-event-card-title').matchHeight({row: true})
          $('.js-event-card-content-type').matchHeight({row: true})
          // $('.js-event-card-speakers').matchHeight({row: true,  property: 'min-height'})
          $('.js-event-card-speaker-buttons').matchHeight({row: true,  property: 'min-height'})
          $.fn.matchHeight._update()
        });
    },
    onOpened: function (e) {
      this.lastOpened = e.index
    },
    onLastSpeakerOpened: function (e) {
      this.lastSpeakerOpened = e.index
    },
    filterCards: function () {
      this.filteredCards = this.localResourcefields.cards
      const filterKeys = Object.keys(this.selected)
      const filters = this.localResourcefields.filters
      if (Array.isArray(filterKeys)) {
        filterKeys.forEach(key => {
          if (this.selected[key].slug) {
            const selected = this.selected[key]
            switch (filters[key].type) {
              case 'location':
                this.filteredCards = this.filteredCards.filter(card => {
                  if (card.event === undefined) return false
                  if (card.event.location === undefined) return false
                  if (card.event.location.location === undefined) return false
                  return card.event.location.location === selected.slug
                })
                break;
              case 'event_type':
                this.filteredCards = this.filteredCards.filter(card => {
                  return card.contentTypes[0].slug === selected.slug
                })
                break;
              case 'content_type':
                this.filteredCards = this.filteredCards.filter(card => {
                  if (!card.contentTypes) return false
                  if (!card.contentTypes[0].slug) return false
                  return card.contentTypes[0].slug === selected.slug
                })
                break;
            }
          }
        })
      }
    },
    getSelected: function (e) {
      this.filteredCards = this.localResourcefields.cards
      if (!e) {
        return
      }
      this.selected = {...this.selected, [e.index]: e.item}

      this.filterCards()
      this.updateCardsShownCount()
      this.equalHeight()
    }
  },
}
</script>
