<template>
  <a :href="permalink"
     class="permalink text-decoration-none">
        <span class="watch-time">
          <p class="watch-time-text">
           <slot></slot>
          </p>
        <span class="cta-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px"
                  d="M6.23 10h7.54l-3.33-3.33L13.77 10l-3.33 3.33"></path>
        </svg>
    </span>
        </span>
  </a>
</template>
<script>
export default {
  props: ['permalink']
}
</script>
