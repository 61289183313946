var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "speakers js-event-card-speakers" }, [
    _c(
      "div",
      { staticClass: "speaker-buttons js-event-card-speaker-buttons" },
      [
        _vm.hasSpeakers
          ? _vm._l(_vm.speakers, function (speaker) {
              return _c(
                "button",
                {
                  key: speaker.id,
                  class: { active: _vm.activeSpeakerId === speaker.id },
                  attrs: { "data-speaker-details": speaker.id },
                  on: {
                    click: function ($event) {
                      return _vm.setActiveSpeakerId($event, speaker.id)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "33px",
                      height: "33px",
                      "border-radius": "50%",
                    },
                    attrs: { src: speaker.image.url },
                  }),
                ]
              )
            })
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _vm.hasSpeakers
      ? _c("div", { staticClass: "speakers-details" }, [
          _vm.speakers.filter((e) => e.id === _vm.activeSpeakerId)[0]
            ? _c(
                "div",
                {
                  staticClass: "speaker-detail",
                  class: {
                    active: _vm.activeSpeakerId,
                    show: _vm.showActiveSpeaker,
                  },
                },
                [
                  _c("p", { staticClass: "speaker-name" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.speakers.filter(
                            (e) => e.id === _vm.activeSpeakerId
                          )[0].name
                        ) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "speaker-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.speakers.filter(
                            (e) => e.id === _vm.activeSpeakerId
                          )[0].title
                        ) +
                        "\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }