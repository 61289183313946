var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    { staticClass: "eyebrow short-copy text-uppercase", class: _vm.classes },
    [_vm._v("\n  " + _vm._s(_vm.text) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }