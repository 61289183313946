<template>
  <div class="hig-drop-down"
       :data-value-target="dropdownData.name"
       style="min-width: 100%; max-width: 100%;"
  >
    <input type="hidden"
           :name="dropdownData.name"
           value=""
           :data-wat-val="dropdownData.dataWatVal"
    />
    <button
        @click="toggleDropdown"
        class="selected">
      <span class="">{{ selectedOption.name !== undefined ? selectedOption.name : dropdownData.placeholder }}</span>
      <span>
        <!-- arrow up -->
            <svg class="arrow-up d-none" width="12" height="16" viewBox="0 0 12 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <path d="M10.999 10.5L5.99902 5.5L0.999023 10.5" stroke="black" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        <!-- -->
        <!-- arrow down -->
            <svg class="arrow-down" width="12" height="16" viewBox="0 0 12 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <path d="M10.999 5.5L5.99902 10.5L0.999023 5.5" stroke="black" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        <!-- -->
      </span>
    </button>
    <div class=" hig-drop-down-options"
         :class="{active : isOpen}"
    >
      <button
          class="connector-plugin-type-filter  h5 "
          :class="{active: (selectedOption.slug === undefined)}"
          data-target="all"
          @click="selectOption($event, {slug: undefined})"
      >
        <span class="option-text ">{{ dropdownData.placeholder }}</span>
        <span class="selected-icon">
            <svg width="11" height="16" viewBox="0 0 11 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M0.999512 7.40887L4.18133 11.0015L9.99951 4.50146" stroke="black"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
      </button>

      <button
          v-for="item in dropdownData.items"
          class="connector-plugin-type-filter h5"
          :class="{active : (selectedOption.slug === item.slug)}"
          :data-target="item.slug"
          @click="selectOption($event, item)"
      >
        <span class="option-text">
          {{ item.name }}
        </span>
        <span class="selected-icon">
          <svg width="11" height="16" viewBox="0 0 11 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
              <path d="M0.999512 7.40887L4.18133 11.0015L9.99951 4.50146" stroke="black"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['dropdown','index', 'lastOpened'],
  data() {
    return {
      // dropdownData: this.dropdown.dropdown
      isOpen: false,
      selectedOption: {}
    }
  },
  watch : {
    lastOpened: function (e) {
      if (this.index !== e) {
        this.closeDropdown()
      }
      if (e === -1) {
        this.selectedOption = {}
      }
    }
  },
  methods : {
    closeDropdown: function () {
      this.isOpen = false
    },
    toggleDropdown: function (e) {
      e.preventDefault()
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$emit('onOpened', {index: this.index})
      }
    },
    selectOption: function (e, item) {
      e.preventDefault()
      this.selectedOption = item
      this.closeDropdown()
      this.$emit('onSelected', {item, index: this.index})
    }
  },
  computed: {
    dropdownData () {
      return this.dropdown.dropdown
    }
  }
}
</script>
