var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-base",
    { attrs: { card: _vm.card, "listing-type": _vm.listingType } },
    [
      _vm.listingType === "event_listing"
        ? _c("event-eyebrow", { attrs: { card: _vm.card } })
        : _vm._e(),
      _vm._v(" "),
      _vm.listingType !== "event_listing"
        ? _c("eyebrow", {
            attrs: {
              classes: "mb-8 js-event-card-eyebrow",
              text: _vm.card.contentTypes ? _vm.card.contentTypes[0].name : " ",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "head-line mb-8 h5 title js-event-card-title" }, [
        _vm._v(_vm._s(_vm.card.title)),
      ]),
      _vm._v(" "),
      _vm.listingType === "event_listing"
        ? _c("eyebrow", {
            attrs: {
              classes: "mb-16 js-event-card-content-type",
              text: _vm.card.contentTypes ? _vm.card.contentTypes[0].name : " ",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.listingType === "event_listing"
        ? _c("p", { staticClass: "short-copy mb-16 js-event-card-text" }, [
            _vm._v(_vm._s(_vm.card.body_text)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("speakers", {
        attrs: {
          hasSpeakers: _vm.card.event && _vm.card.event.speakers,
          speakers:
            _vm.card.event && _vm.card.event.speakers
              ? _vm.card.event.speakers
              : false,
          cardId: _vm.card.id,
          lastOpened: _vm.lastOpened,
        },
        on: { activeSpeakerChanged: _vm.activeSpeakerChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }