<template>
    <card-base :card="card" :listing-type="listingType">
        <event-eyebrow v-if="listingType === 'event_listing'" :card="card" />
        <eyebrow
            v-if="listingType !== 'event_listing'"
            :classes="'mb-8 js-event-card-eyebrow'"
            :text="card.contentTypes ? card.contentTypes[0].name : ' '" />
        <p class="head-line mb-8 h5 title js-event-card-title">{{ card.title }}</p>
        <eyebrow
            v-if="listingType === 'event_listing'"
            :classes="'mb-16 js-event-card-content-type'"
            :text="card.contentTypes ? card.contentTypes[0].name : ' '" />

        <p v-if="listingType === 'event_listing'"
           class="short-copy mb-16 js-event-card-text">{{ card.body_text }}</p>
        <speakers
            :hasSpeakers="card.event && card.event.speakers"
            :speakers="(card.event && card.event.speakers) ? card.event.speakers : false"
            :cardId="card.id"
            :lastOpened="lastOpened"
            @activeSpeakerChanged="activeSpeakerChanged" />
    </card-base>
</template>
<script>
import EventEyebrow from './card-parts/event-eyebrow.vue'
import Eyebrow from './card-parts/eyebrow.vue'
import CardImage from './card-parts/card-image.vue'
import CardLink from './card-parts/card-link.vue'
import CardBase from './card-parts/card-base.vue'
import Speakers from './card-parts/speakers.vue'

export default {
  components: { Speakers, CardBase, CardLink, Eyebrow, EventEyebrow, CardImage },
  props: ['card', 'lastOpened', 'listingType'],
  data() {
    return {
      isOpen: false,
      selectedOption: {},
    }
  },
  methods: {
    activeSpeakerChanged (e) {
      this.$emit('activeSpeakerChanged', {index: e.index})
    }
  }
}

</script>
