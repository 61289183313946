<template>
  <p class="eyebrow short-copy text-uppercase"
     :class="classes"
  >
    {{text}}
  </p>
</template>
<script>
  export default {
    props: ['text', 'classes']
  }
</script>
