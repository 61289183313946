<template>
  <div class="image-container">
    <div
        v-if="image"
        class="image"
        :style="{background: 'url(\'' + image.sizes.cardResource_md + '\') no-repeat center center / ' + localObjectFit + ' ' + bgColor + ''}"
    ></div>
  </div>
</template>
<script>
export default {
  props: ['image', 'objectFit', 'bgColor'],
  data() {
    return {
      localObjectFit: this.objectFit || 'cover'
    }
  },
}
</script>
