var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-resource card-event" },
    [
      _c("card-image", {
        attrs: {
          image: _vm.card.image,
          "object-fit": _vm.card.objectFit,
          "bg-color": _vm.card.profile_image_background_color,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rebrand-content" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "card-link",
            { attrs: { permalink: _vm.card.permalink } },
            [
              _vm.listingType === "event_listing" ||
              (!_vm.card.readTime && !_vm.card.watchTime)
                ? [[_vm._v("\n          View details\n        ")]]
                : _vm._e(),
              _vm._v(" "),
              _vm.listingType !== "event_listing"
                ? [
                    _vm.card.readTime
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.card.readTime) +
                              "\n        "
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.card.readTime && _vm.card.watchTime
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.card.watchTime) +
                              "\n        "
                          ),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }