<template>
  <div class="card-resource card-event">
    <card-image :image="card.image" :object-fit="card.objectFit" :bg-color="card.profile_image_background_color" />
    <div class="rebrand-content">
      <slot></slot>
      <card-link :permalink="card.permalink">
        <template v-if="listingType === 'event_listing' || (!card.readTime && !card.watchTime)">
          <template>
            View details
          </template>
        </template>

        <template v-if="listingType !== 'event_listing'">
          <template v-if="card.readTime">
            {{card.readTime}}
          </template>
          <template v-if="!card.readTime && card.watchTime">
            {{card.watchTime}}
          </template>
        </template>
      </card-link>
    </div>
  </div>
</template>
<script>
import Eyebrow from './eyebrow.vue'
import CardLink from './card-link.vue'
import CardImage from './card-image.vue'

export default {
  components: { CardLink, Eyebrow, CardImage},
  props: ['card', 'listingType']
}
</script>
