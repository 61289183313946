var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container p-0" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 rebrand-content mb-48" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._l(_vm.localFilters, function (filter, i) {
              return [
                _c(
                  "div",
                  { staticClass: "col-lg-3 col-md-6" },
                  [
                    _c("label", { attrs: { for: "content_type_select" } }, [
                      _vm._v(_vm._s(filter.title)),
                    ]),
                    _vm._v(" "),
                    _c("hig-dropdown", {
                      key: "filter_" + i,
                      attrs: {
                        index: i,
                        lastOpened: _vm.lastOpened,
                        dropdown: filter,
                      },
                      on: {
                        onSelected: _vm.getSelected,
                        onOpened: _vm.onOpened,
                      },
                    }),
                  ],
                  1
                ),
              ]
            }),
            _vm._v(" "),
            _vm.localFilters && _vm.localFilters.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-lg-3 col-md-6 form__buttons d-flex align-items-end",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-buttons d-flex align-items-center" },
                      [
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "ml-2 d-inline-block cta-secondary btn",
                              attrs: { href: "#" },
                              on: { click: _vm.resetFilters },
                            },
                            [_vm._v("Reset Filters")]
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row card-list" },
      [
        _vm._l(_vm.cardsShownCount, function (i) {
          return [
            _vm.filteredCards[i - 1] !== undefined
              ? _c(
                  "div",
                  { staticClass: "col-sm-6 col-lg-3" },
                  [
                    _c("event-card", {
                      key: "event_card_" + i,
                      attrs: {
                        card: _vm.filteredCards[i - 1],
                        lastOpened: _vm.lastSpeakerOpened,
                        listingType: _vm.listingType,
                      },
                      on: { activeSpeakerChanged: _vm.onLastSpeakerOpened },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        }),
        _vm._v(" "),
        _vm.filteredCards.length === 0
          ? _c("p", [_vm._v(_vm._s(_vm.noResultsMessage))])
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _vm.cardsShownCount < _vm.filteredCards.length
          ? _c(
              "button",
              {
                staticClass: "button-see-more",
                on: {
                  click: () => {
                    _vm.cardsShownCount +=
                      _vm.localResourcefields.posts_per_page
                    _vm.equalHeight()
                  },
                },
              },
              [
                _c("span", { staticClass: "button-icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "21",
                        viewBox: "0 0 20 21",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M13.1196 10.4832L9.49997 14.1028M9.49997 14.1028L5.8803 10.4832M9.49997 14.1028L9.49997 5.93738",
                          stroke: "black",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "button-text" }, [
                  _vm._v(
                    "\n                          See more\n                      "
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }