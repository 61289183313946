import Vue from 'vue'
import App from './ResourceListing.vue'
import $ from 'jquery'
import 'jquery-match-height'
// polyfill for Object.entries
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}
let resourceListing = document.getElementsByClassName("js-resource-listing-container-vue");
for (let i = 0; i < resourceListing.length; i++) {
  let res = resourceListing[i]
  Vue.prototype.resourceListFields = window.resourceListings[res.dataset.target]
  Vue.prototype.$ = $
  let id = res.id;
  new Vue({
    render: h => h(App),
  }).$mount('#' + res.dataset.target)
}
