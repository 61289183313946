var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "permalink text-decoration-none",
      attrs: { href: _vm.permalink },
    },
    [
      _c("span", { staticClass: "watch-time" }, [
        _c("p", { staticClass: "watch-time-text" }, [_vm._t("default")], 2),
        _vm._v(" "),
        _c("span", { staticClass: "cta-icon" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 20 20",
              },
            },
            [
              _c("path", {
                staticStyle: {
                  fill: "none",
                  stroke: "#000",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "1.3px",
                },
                attrs: { d: "M6.23 10h7.54l-3.33-3.33L13.77 10l-3.33 3.33" },
              }),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }