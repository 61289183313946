var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "image-container" }, [
    _vm.image
      ? _c("div", {
          staticClass: "image",
          style: {
            background:
              "url('" +
              _vm.image.sizes.cardResource_md +
              "') no-repeat center center / " +
              _vm.localObjectFit +
              " " +
              _vm.bgColor +
              "",
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }